<template>
  <div class="edit-instant-win">
    <header class="card-header">
      <div class="d-flex justify-content-between align-items-center">
        <strong class="title">Edit Tiles</strong>
        <div class="message" v-if="!isTilesDataSaved">Data not saved!</div>
        <CButton
          class="action-create-button zq--responsive-button__common"
          type="submit"
          @click="updateTiles"
          :disabled="isTilesDataSaved"
        >
          Update Tiles
        </CButton>
      </div>
    </header>
    <div class="update-tiles-step">
      <CForm
        ref="createSettings"
        novalidate
        class="create-form"
      >
        <div class="form-content content">
          <CRow class="p-0 m-0 col-12">

            <div class="col-12">
              <CRow class="p-0 m-0 col-12">
                <CCol col="6" class="p-0 col-6">
                  <div v-if="instantWinType === 1" class="wheel-settings w-100 flex-column">
<!--                    <div class="mb-4">{{ texts.createPage.tilesStep.wheelSettingsTitle }}</div>-->

                    <!--                  <div class="mb-0 settings-title">SPIN Button settings</div>-->
                    <!--                  &lt;!&ndash;  Button  Text   &ndash;&gt;-->
                    <!--                  <div class="flex-column text-block">-->
                    <!--                    <span class="tag-label-text">{{ texts.createPage.tilesStep.buttonTextLabel }}</span>-->
                    <!--                    <ClEditor-->
                    <!--                      ref="spinButtonText"-->
                    <!--                      @change="onEditorSpinButtonTextChange"-->
                    <!--                      :is-toolbar="true"-->
                    <!--                      :value-prop="localWheelSettings.buttonText"-->
                    <!--                      :placeholder="'placeholder'"-->
                    <!--                      :disabled="false"-->
                    <!--                      :name="'spinButtonText'"-->
                    <!--                      :editorOptionsProps="editorOptions"-->
                    <!--                      :editorHeight="50"-->
                    <!--                    />-->
                    <!--                  </div>-->

                    <!--                  <div class="d-flex align-items-center mb-3">-->
                    <!--                    &lt;!&ndash;   Icon   &ndash;&gt;-->
                    <!--                    <div class="flex-column w-50" >-->
                    <!--                      <CIIconField-->
                    <!--                        class="w-100"-->
                    <!--                        v-model="localWheelSettings.icon"-->
                    <!--                        :key="localWheelSettings.icon"-->
                    <!--                        @deleteFile="handleDeleteButtonIconFile"-->
                    <!--                        :isColumn="true"-->
                    <!--                        :isPopup="true"-->
                    <!--                        :descriptionKey="'buttonIcon'"-->
                    <!--                      />-->
                    <!--                    </div>-->
                    <!--                    &lt;!&ndash;  Background Image   &ndash;&gt;-->
                    <!--                    <div class="flex-column w-50" >-->
                    <!--                      <CIIconField-->
                    <!--                        class="w-100"-->
                    <!--                        v-model="localWheelSettings.spinButtonBackgroundImage"-->
                    <!--                        :key="localWheelSettings.spinButtonBackgroundImage"-->
                    <!--                        :label="texts.createPage.tilesStep.spinBtnBgBackgroundImageLabel"-->
                    <!--                        @deleteFile="handleDeleteButtonBackgroundFile"-->
                    <!--                        :isColumn="true"-->
                    <!--                        :isPopup="true"-->
                    <!--                        :descriptionKey="'spinButtonBackgroundImage'"-->
                    <!--                        :descriptions="descriptions.descriptionIcons"-->
                    <!--                      />-->
                    <!--                    </div>-->
                    <!--                  </div>-->

                    <!--                  <div class="d-flex align-items-center mb-4">-->
                    <!--                    &lt;!&ndash; Spin Button background color &ndash;&gt;-->
                    <!--                    <div class="flex-column w-50">-->
                    <!--                      <div class="zq&#45;&#45;form-row&#45;&#45;label pt-2">-->
                    <!--                        <span class="tag-label-text">{{ texts.createPage.tilesStep.spinBtnBorderColorLabel }}</span>-->
                    <!--                      </div>-->
                    <!--                      <div class="d-flex align-items-center">-->
                    <!--                        <ColorPicker-->
                    <!--                          :key="localWheelSettings.spinButtonBorderColor"-->
                    <!--                          @colorSelected="handleSpinButtonBorderColorSelected"-->
                    <!--                          :selectedPropColor="localWheelSettings.spinButtonBorderColor"-->
                    <!--                        />-->
                    <!--                        <IconWithTooltip class="ml-2" :text="descriptions.wheelButtonBorderColor"/>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    &lt;!&ndash; Spin Button background color &ndash;&gt;-->
                    <!--                    <div class="flex-column w-50" >-->
                    <!--                      <div class="zq&#45;&#45;form-row&#45;&#45;label pt-2">-->
                    <!--                        <span class="tag-label-text">{{ texts.createPage.tilesStep.spinBtnBgColorLabel }}</span>-->
                    <!--                      </div>-->
                    <!--                      <div class="d-flex align-items-center">-->
                    <!--                        <ColorPicker-->
                    <!--                          :key="localWheelSettings.spinButtonBackground"-->
                    <!--                          @colorSelected="handleSpinButtonBackgroundColorSelected"-->
                    <!--                          :selectedPropColor="localWheelSettings.spinButtonBackground"-->
                    <!--                        />-->
                    <!--                        <IconWithTooltip class="ml-2" :text="descriptions.wheelButtonBgColor"/>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                  </div>-->

                    <!--                  <div class="mt-2 settings-title">Wheel Border Settings</div>-->

                    <!--                  <div class="d-flex align-items-center mb-4">-->
                    <!--                    &lt;!&ndash; Wheel border background color &ndash;&gt;-->
                    <!--                    <div class="flex-column w-50" >-->
                    <!--                      <div class="zq&#45;&#45;form-row&#45;&#45;label pt-2">-->
                    <!--                        <span class="tag-label-text">{{ texts.createPage.tilesStep.wheelBgColorLabel }}</span>-->
                    <!--                      </div>-->
                    <!--                      <div class="d-flex align-items-center">-->
                    <!--                        <ColorPicker-->
                    <!--                            :key="localWheelSettings.wheelBackground"-->
                    <!--                            @colorSelected="handleWheelBackgroundColorSelected"-->
                    <!--                            :selectedPropColor="localWheelSettings.wheelBackground"-->
                    <!--                        />-->
                    <!--                        <IconWithTooltip class="ml-2" :text="descriptions.tileBgColor"/>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    &lt;!&ndash; Wheel borders  color &ndash;&gt;-->
                    <!--                    <div class="flex-column w-50" >-->
                    <!--                      <div class="zq&#45;&#45;form-row&#45;&#45;label pt-2">-->
                    <!--                        <span class="tag-label-text">{{ texts.createPage.tilesStep.wheelBordersColorLabel }}</span>-->
                    <!--                      </div>-->
                    <!--                      <div class="d-flex align-items-center">-->
                    <!--                        <ColorPicker-->
                    <!--                            :key="localWheelSettings.wheelBordersColor"-->
                    <!--                            @colorSelected="handleWheelBordersColorSelected"-->
                    <!--                            :selectedPropColor="localWheelSettings.wheelBordersColor"-->
                    <!--                        />-->
                    <!--                        <IconWithTooltip class="ml-2" :text="descriptions.wheelBorderColor"/>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                  </div>-->

                    <div class="settings-title">Whole wheel image</div>

                    <div class="d-flex align-items-center mb-3">
                      <!--   Wheel Image   -->
                      <div class="flex-column w-50 mr-5">
                        <CIIconField
                          class="w-100"
                          :key="localWheelSettings.wheelBorderImage ? localWheelSettings.wheelBorderImage : 'wheelBorderImage'"
                          v-model="localWheelSettings.wheelBorderImage"
                          :label="texts.createPage.tilesStep.wheelBorderImageLabel"
                          @deleteFile="handleDeleteWheelBorderImageFile"
                          :isColumn="true"
                          :isPopup="true"
                          :descriptionKey="'wheelBorderImage'"
                          :descriptions="descriptions.descriptionIcons"
                        />
                      </div>
                      <!--  Wheel Button Image   -->
                      <div class="flex-column w-50  mr-5">
                        <CIIconField
                          class="w-100"
                          :key="localWheelSettings.wheelButtonImage ? localWheelSettings.wheelButtonImage : 'wheelButtonImage'"
                          v-model="localWheelSettings.wheelButtonImage"
                          :label="texts.createPage.tilesStep.wheelButtonImageLabel"
                          @deleteFile="handleDeleteWheelButtonImageFile"
                          :isColumn="true"
                          :isPopup="true"
                          :descriptionKey="'wheelButtonImage'"
                          :descriptions="descriptions.descriptionIcons"
                        />
                      </div>
                    </div>

                    <div class="d-flex align-items-center mb-3">
                      <!--   Wheel Image   -->
                      <div class="flex-column w-50 mr-5">
                        <CIIconField
                          class="w-100"
                          :key="localWheelSettings.wheelImage ? localWheelSettings.wheelImage : 'wheelImage'"
                          v-model="localWheelSettings.wheelImage"
                          :label="texts.createPage.tilesStep.wheelImageLabel"
                          @deleteFile="handleDeleteWheelImageFile"
                          :isColumn="true"
                          :isPopup="true"
                          :descriptionKey="'wheelImage'"
                          :descriptions="descriptions.descriptionIcons"
                        />
                      </div>
                      <div class="flex-column w-25">
                        <div class="zq--form-row--label">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.showWheelImageLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <CSwitch
                            color="success"
                            shape="pill"
                            :value="isShowWheelImage"
                            :checked="isShowWheelImage"
                            class="zq--switch"
                            :labelOn="'on'"
                            :labelOff="'off'"
                            :disabled="!localWheelSettings.wheelImage"
                            @update:checked="showWheelImageUpdate"
                          />
                          <IconWithTooltip class="ml-2" :text="descriptions.showWheelImage"/>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex align-items-center mb-3">
                      <!--   Wheel Arrow   -->
                      <div class="flex-column w-50 mr-5">
                        <CIIconField
                          ref="wheelArrowImageIconField"
                          class="w-100"
                          :key="localWheelSettings.wheelArrowImage ? localWheelSettings.wheelArrowImage : 'wheelArrowImage'"
                          v-model="localWheelSettings.wheelArrowImage"
                          :label="texts.createPage.tilesStep.wheelArrowImageLabel"
                          @deleteFile="handleDeleteWheelArrowImageFile"
                          :isColumn="true"
                          :isPopup="true"
                          :descriptionKey="'wheelArrowImage'"
                          :descriptions="descriptions.descriptionIcons"
                        />
                      </div>
                    </div>

                    <ClResponsiveButton
                      class="mt-4"
                      :label="$t('buttons.updateWheel')"
                      @handler="updateWheelSettings"
                    />
                  </div>
                </CCol>
                <!-- Right block -->
                <CCol col="6" class="p-0 col-6 right-block">
                  <div class="charts-switch-row" v-if="instantWinType === 1">
                    <TabSwitcher
                      class="tabSwitcher"
                      :tabs="tabsList"
                      :width="'422px'"
                      :height="'38px'"
                      @toggle="handleTabToggle"
                    />
                    <IconWithTooltip class="tabSwitcher-tooltip" :text="descriptions.switchBetweenCharts"/>
                  </div>
                  <div class="create-wheel-wrapper" v-if="instantWinType === 1">
                    <!-- wheelPreview barGraph pieChart -->
                    <Wheel
                      v-if="activeTab === 'wheelPreview'"
                      ref="wheel"
                      :sections-count="localTilesCount"
                      :key="rerenderWheelKey"
                      :tilesData="localTiles"
                      :isVerticallyText="isVerticallyText"
                      @handleTileSelected="handleTileSelected"
                      :wheelSettings="localWheelSettings"
                      :isShowWheelImage="isShowWheelImage"
                    />
                    <WheelTilesPieChart
                      v-if="activeTab === 'pieChart'"
                      :data="localTiles"
                      :key="renderChartKey"
                      :chartsData="localChartsData"
                    />
                    <BarChart
                      v-if="activeTab === 'barGraph'"
                      :key="renderChartKey"
                      :tilesData="localTiles"
                      :chartsData="localChartsData"
                    />
                  </div>
                  <div class="card-preview-wrapper flex-column" v-if="instantWinType === 2">
                    <div class="d-flex align-items-center justify-content-between probability-view">
                      <div>
                        <div
                          @click="handleIsScratchCardProbabilityView()"
                          v-if="isScratchCardProbabilityView"
                          class="probability-view-btn"
                        >
                          <img src="@/assets/icons/blue-back-arrow.svg" alt="back-arrow"> Back
                        </div>
                      </div>
                      <div>
                        <div
                          @click="handleIsScratchCardProbabilityView()"
                          v-if="!isScratchCardProbabilityView"
                          class="probability-view-btn"
                        >
                          Probability view <img src="@/assets/icons/chartBar.svg" alt="chartBar">
                        </div>
                      </div>
                    </div>
                    <ScratchCard
                      v-if="!isScratchCardProbabilityView"
                      ref="scratchcard"
                      :tilesData="localTiles"
                      :tilesCount="localTilesCount"
                      :cols="localCols"
                      :rows="localRows"
                      @handleTileSelected="handleTileSelected"
                      :selectedTile="selectedTile.value"
                    />
                    <BarChart
                      v-if="isScratchCardProbabilityView"
                      :key="renderChartKey"
                      :tilesData="localTiles"
                      :chartsData="localChartsData"
                      :instantWinType="instantWinType"
                    />
                  </div>

                </CCol>
                <!-- Right block ended -->

                <CCol class="pt-2 pl-0">
                  <CCol col="6" class="p-0 col-6">
                    <div class="dividing-line mt-4 mb-4"></div>

                    <span>Tile settings</span>
                    <div v-if="instantWinType === 2" class="d-flex align-items-center p-0 m-0 ">
                      <CIInputCol
                        type="number"
                        min="2"
                        max="7"
                        class="w-50 align-items-center pr-2"
                        :value="localCols"
                        :label="texts.createPage.tilesStep.colLabel"
                        v-model="localCols"
                        name="cols"
                        :isTooltip="false"
                      />

                      <CIInputCol
                        type="number"
                        min="2"
                        max="7"
                        class="w-50 align-items-center"
                        :value="localRows"
                        :label="texts.createPage.tilesStep.rowLabel"
                        v-model="localRows"
                        name="rows"
                        :isTooltip="false"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <!--   How many tiles   -->
                      <CIInputCol
                        type="number"
                        min="2"
                        class="w-50 align-items-center pr-2"
                        :value="localTilesCount"
                        :label="texts.createPage.tilesStep.tilesCountLabel"
                        v-model="localTilesCount"
                        name="tilesCount"
                        :isTooltip="false"
                        :tooltip="'text test'"
                        :disabled="instantWinType === 2"
                      />
                      <!--   Choose tile   -->
                      <div class="flex-column w-50">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.tileLabel }}</span>
                        <div class="d-flex align-items-center">
                          <multiselect
                            class="custom-multi-select zq--multiselect w-100"
                            v-model="selectedTile"
                            label="label"
                            track-by="value"
                            :options="sectionsOptions"
                            :hide-selected="true"
                            @input="onInput"
                          >
                          </multiselect>
                          <IconWithTooltip :text="descriptions.selectTile" class="ml-2"/>
                        </div>
                      </div>
                    </div>

                    <!--   autoProbability   -->
                    <div class="flex-column w-25">
                      <div class="zq--form-row--label">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.autoProbabilityLabel }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <CSwitch
                          color="success"
                          shape="pill"
                          :value="localAutoProbability"
                          :checked="localAutoProbability"
                          class="zq--switch"
                          :labelOn="'on'"
                          :labelOff="'off'"
                          :disabled="false"
                          @update:checked="autoProbabilityUpdate"
                        />
                        <IconWithTooltip class="ml-2" :text="descriptions.autoProbability"/>
                      </div>
                    </div>
                    <!--   Probability     -->
                    <CIInputCol
                      v-if="!localAutoProbability"
                      type="number"
                      step="0.000001"
                      min="0.000001"
                      max="0.999999"
                      :value="tile.probability"
                      :label="texts.createPage.tilesStep.probabilityLabel"
                      v-model="tile.probability"
                      name="probability"
                      :isTooltip="true"
                      :toolTipText="descriptions.probability"
                      :requiredText="requiredText"
                      @input="onProbabilityInput"
                      :disabled="false"
                    />

                    <!--  has prize - show reward-->
                    <div class="flex-column">
                      <div class="zq--form-row--label">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.hasPrizeLabel }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <CSwitch
                          color="success"
                          shape="pill"
                          :value="hasPrizes"
                          :checked="hasPrizes"
                          class="zq--switch"
                          :labelOn="'on'"
                          :labelOff="'off'"
                          :disabled="false"
                          @update:checked="hasPrizesUpdate"
                        />
                        <IconWithTooltip class="ml-2" :text="descriptions.hasPrize"/>
                      </div>
                    </div>
                    <div class="flex-column col-6 p-0" v-if="hasPrizes && Object.keys(tile.reward).length !== 0">
                      <div class="zq--form-row--label">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.rewardLabel }}</span>
                      </div>
                      <table v-if="Object.keys(tile.reward).length !== 0" class="reward-table">
                        <thead>
                        <tr>
                          <th>Name</th>
                          <th>Rank</th>
                          <th>Value</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="tile.reward.name">
                          <td :title="tile.reward.name" class="ellipsis">{{ tile.reward.name }}</td>
                          <td :title="tile.reward.rewardRank" class="ellipsis">{{ tile.reward.rewardRank }}</td>
                          <td :title="tile.reward.rewardValue" class="ellipsis">{{ tile.reward.rewardValue }}</td>
                          <td>
                            <span class="link" @click="removeReward()">Delete</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <!--   Text on the tile   -->
                    <div v-if="isTileSettingsAvailable" class="flex-column text-block mt-3">
                      <span class="tag-label-text">{{ texts.createPage.tilesStep.textLabel }}</span>
                      <ClEditor
                        ref="text"
                        @change="onEditorChange"
                        :is-toolbar="true"
                        :value-prop="tile.text"
                        :placeholder="'placeholder'"
                        :disabled="false"
                        :name="'text'"
                        :editorOptionsProps="editorOptions"
                        :editorHeight="100"
                      />
                    </div>

                    <div v-if="isTileSettingsAvailable" class="d-flex align-items-center justify-content-between">
                      <!--   Switch tile text position   -->
                      <div class="flex-column mt-3" v-if="instantWinType === 1">
                        <div class="zq--form-row--label">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.tileTextPositionLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <SwitchButton
                            :isChecked="isVerticallyText"
                            :textLabelOn="'Horizontally'"
                            :textLabelOff="'Vertically'"
                            name="verticallyTextToggle"
                            @toggle="swipeTextPositionHandler"
                            :height="'42px'"
                            :width="'200px'"
                          />
                          <IconWithTooltip :text="descriptions.tileTextPosition"/>
                        </div>
                      </div>

                      <!--   Icon   -->
                      <CIIconField
                        v-model="tile.icon"
                        :key="selectedTile.value"
                        @deleteFile="handleDeleteFile"
                        :isColumn="true"
                        :isPopup="true"
                        :descriptionKey="'tileIcon'"
                        :descriptions="descriptions.descriptionIcons"
                      />

                      <!-- Tile background color -->
                      <div class="flex-column" v-if="!isHiddenTileBgButton">
                        <div class="zq--form-row--label pt-2">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.tileBgColorLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <ColorPicker
                            :key="tile.background"
                            @colorSelected="handleColorSelected"
                            :selectedPropColor="tile.background"
                          />
                          <IconWithTooltip class="ml-2" :text="descriptions.tileBgColor"/>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CCol>
              </CRow>
            </div>
            <!-- Wheel preview ended -->
          </CRow>
        </div>
      </CForm>
    </div>
    <PreviewSpiner v-if="!isReady" class="spinner"/>
    <!-- MODALS   -->
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="messageGeneral"
      :title="modalTitle"
      :cancelBtnLabel="'Add from template'"
      :successBtnLabel="'Create'"
      @doFunction="createReward"
      @closeModal="closeModal"
      v-on:toggle-modal="addRewardFromTemplate()"
      :isSuccessButton="true"
    />
    <RewardTemplatesModal
      :isShowRewardTemplatesModal="isShowRewardTemplatesModal"
      @selectReward="selectReward"
      @closeModal="closeModal"
    />
    <CreateRewardModal
      :isShowRewardModal="isShowRewardModal"
      :selectedReward="selectedReward"
      @updateRewardData="updateRewardData"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import { instantWins } from '@/config/descriptions/instantWins.json';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import { cloneDeep } from 'lodash';
import Wheel from '@/views/ziqni/instant-wins/components/Wheel';
import CIIconField from '@/shared/components/CIIconField/CIIconField';
import WheelTilesPieChart from '@/views/ziqni/instant-wins/components/WheelTilesPieChart';
import BarChart from '@/views/ziqni/instant-wins/components/BarChart';
import ScratchCard from '@/views/ziqni/instant-wins/components/ScratchCard';
import { mapActions } from 'vuex';
import PreviewSpiner from '@/shared/UI/Spiner';
import PreviewRewardTable from '@/shared/components/supportModels/instantWins/PreviewRewardTable.vue';
import SwitchButton from '@/shared/UI/SwitchButton';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import TabSwitcher from '@/shared/UI/TabSwitcher.vue';
import CIInputCol from '@/shared/components/formComponents/CIInputCol.vue';
import ClEditor from '@/shared/components/formComponents/ClEditor.vue';
import ColorPicker from '@/shared/UI/ColorPicker.vue';
import { Quill } from 'vue2-editor';
import { updateSelectedSection } from '@/views/ziqni/instant-wins/components/helpers/wheelSections';
import Modal from '@/shared/UI/Modal.vue';
import RewardTemplatesModal from '@/views/ziqni/instant-wins/RewardTemplatesModal.vue';
import CreateRewardModal from '@/views/ziqni/instant-wins/CreateRewardModal.vue';

function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, '-');
}

// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = '';
fontList.forEach(function (font) {
  let fontName = getFontName(font);
  fontStyles += '.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=' + fontName + ']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=' + fontName + ']::before {' +
    'content: \'' + font + '\';' +
    'font-family: \'' + font + '\', sans-serif;' +
    '}' +
    '.ql-font-' + fontName + '{' +
    ' font-family: \'' + font + '\', sans-serif;' +
    '}';
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  name: 'EditInstantWinTiles',
  components: {
    CreateRewardModal,
    RewardTemplatesModal,
    Modal,
    ColorPicker,
    ClEditor,
    CIInputCol,
    TabSwitcher,
    IconWithTooltip,
    SwitchButton,
    PreviewRewardTable,
    PreviewSpiner,
    ScratchCard,
    BarChart,
    WheelTilesPieChart,
    CIIconField,
    Wheel,
    ClResponsiveButton,
    CreateRewards
  },
  props: {
    entityData: Object,
    tilesData: Array,
    instantWinType: [Number],
    cols: [String, Number],
    rows: [String, Number],
  },
  data() {
    return {
      texts: {
        ...instantWinsTexts,
      },
      descriptions: {
        icon: instantWins.create.tiles.icon,
        descriptionIcons: instantWins.create.tiles,
        probability: instantWins.create.tiles.probability,
        autoProbability: instantWins.create.tiles.autoProbability,
        reward: instantWins.create.tiles.reward,
        text: instantWins.create.tiles.text,
        location: instantWins.create.tiles.location,
        probabilityRequiredText: instantWins.create.tiles.probabilityRequiredText,
        probabilityShouldNotBeZero: instantWins.create.tiles.probabilityShouldNotBeZero,
        probabilityNoNumbersGreaterThan: instantWins.create.tiles.probabilityNoNumbersGreaterThan,
        probabilityNoNegativeNumbers: instantWins.create.tiles.probabilityNoNegativeNumbers,
        switchBetweenCharts: instantWins.create.tiles.switchBetweenCharts,
        selectTile: instantWins.create.tiles.selectTile,
      },
      isToolbar: false,
      hasPrizes: false,
      // glow: false,
      iconValidate: null,
      tableFields: ['row', 'col', 'contraints', 'probability', 'reward', 'text'],
      tile: {
        icon: '',
        text: '',
        background: '',
        contraints: [],
        reward: {},
        location: {
          col: 0,
          row: 0
        },
        probability: null
      },
      rewardsData: [],
      transformedRewards: [],
      rerenderKey: 0,
      rerenderWheelKey: 0,
      rerenderScratchcardKey: 0,
      selectedTileIndex: 0,
      requiredFields: ['probability', 'col', 'row'],
      selectedTile: {
        label: 'Tile 1',
        value: '1'
      },
      localTilesCount: this.tilesData.length,
      localCols: this.tilesData[this.tilesData.length - 1].location.col,
      localRows: this.tilesData[this.tilesData.length - 1].location.row,
      isShowQuestionMessage: false,
      localAutoProbability: false,
      tilesOptions: [],
      renderChartKey: 0,
      probabilityPercentage: 0,
      isPieChart: false,
      isProbabilityZero: false,
      localChartsData: [],
      localTiles: null,
      isTilesDataSaved: true,
      isReady: true,
      interaction: false,
      tileReward: [],
      requiredText: null,
      localWheelSettings: {
        buttonText: '',
        wheelBackground: '',
        wheelBordersColor: '',
        wheelImage: '',
        wheelArrowImage: '',
        wheelButtonImage: '',
        wheelBorderImage: '',
        spinButtonBorderColor: '',
        spinButtonBackground: '',
        spinButtonBackgroundImage: '',
        icon: ''
      },
      messageSettings: {},
      isShowWheelImage: false,
      fileObject: null,
      isHiddenTileBgButton: false,
      rerenderSelectColorKey: 0,
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
              ],
              [{ color: [] }],
              [{ 'font': fonts.whitelist }],
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ],
          },
        },
      },
      isVerticallyText: false,
      tabsList: [
        { value: 'wheelPreview', label: 'Wheel preview' },
        { value: 'barGraph', label: 'Bar Graph' },
        { value: 'pieChart', label: 'Pie Chart' }
      ],
      activeTab: 'wheelPreview',
      isScratchCardProbabilityView: false,
      colorScale: ['#EE3EC8', '#7ED4E7'],
      folderName: '/instant-wins',
      repositoryId: '',
      isShowModal: false,
      isShowRewardTemplatesModal: false,
      isShowRewardModal: false,
      messageGeneral: 'Want to add a reward from a template or create a new reward?',
      modalTitle: 'Adding a reward',
      selectedReward: {},
      originalTileText: '',
      allowedSpacesForTileSettings: [],
    };
  },
  watch: {
    'tile.reward'(newVal) {
      const index = +this.selectedTile.value - 1;
      const updatedTile = { ...this.tile, autoProbability: this.localAutoProbability };

      if (newVal === undefined) {
        updatedTile.reward = {};
      } else {
        updatedTile.reward = newVal;
      }
      this.$set(this.tile, 'reward', updatedTile.reward);
      const copy = [...this.localTiles];
      copy.splice(index, 1, updatedTile);

      this.localTiles = copy;
    },
    'tile.text'(newVal) {
      const index = +this.selectedTile.value - 1;

      const updatedTile = { ...this.tile, autoProbability: this.localAutoProbability };
      updatedTile.text = newVal;

      const copy = [...this.localTiles];
      copy.splice(index, 1, updatedTile);

      this.localTiles = copy;
    },
    isVerticallyText(newVal) {
      const index = +this.selectedTile.value - 1;
      const updatedTile = {
        ...this.tile,
        autoProbability: this.localAutoProbability,
        contraints: [...this.tile.contraints]
      };

      if (newVal === true) {
        const isVerticallyText = updatedTile.contraints.includes('isVerticallyText');

        if (!isVerticallyText) {
          updatedTile.contraints.push('isVerticallyText');
        }
      } else {
        const index = updatedTile.contraints.indexOf('isVerticallyText');

        if (index !== -1) {
          updatedTile.contraints.splice(index, 1);
        }
      }
      const copy = [...this.localTiles];
      copy.splice(index, 1, updatedTile);

      this.localTiles = copy;
    },
    'tile.icon'(newVal) {
      const index = +this.selectedTile.value - 1;
      const updatedTile = {
        ...this.tile,
        autoProbability: this.localAutoProbability,
      };

      if (newVal === undefined) {
        updatedTile.icon = '';
      } else {
        updatedTile.icon = newVal;
      }

      const copy = [...this.localTiles];
      copy.splice(index, 1, updatedTile);

      this.localTiles = copy;
      if (this.instantWinType === 1) this.$refs.wheel.updateSectionBackgrounds();
      if (this.instantWinType === 2) this.$refs.scratchcard.updateSectionBackgrounds();
    },
    localAutoProbability(newVal) {
      this.processChartsData();

      if (newVal === true) {
        const index = +this.selectedTile.value - 1;
        const updatedTile = { ...this.tile, probability: null, autoProbability: true };
        const copy = [...this.localTiles];
        copy.splice(index, 1, updatedTile);

        this.localTiles = copy;
      }
    },
    localTilesCount(newVal) {
      let tilesCount;
      if (newVal < 2) {
        tilesCount = 2;
      } else {
        tilesCount = newVal;
      }

      if (this.instantWinType !== 2) {
        const newTilesData = [];

        if (tilesCount > this.localTiles.length && this.instantWinType !== 2) {
          newTilesData.push(...this.localTiles);

          for (let i = this.localTiles.length; i < tilesCount; i++) {
            newTilesData.push({
              icon: '',
              text: '',
              contraints: [],
              reward: {},
              location: {
                col: i + 1,
                row: 0
              },
              probability: null,
              autoProbability: false,
              background: this.colorScale[i % this.colorScale.length]
            });
          }
        } else {
          newTilesData.push(...this.localTiles.slice(0, tilesCount));
        }

        this.localTiles = newTilesData;
      }
      this.isTilesDataSaved = false;
      this.processChartsData();
      this.renderChartKey = tilesCount;
      this.rerenderWheelKey = tilesCount;
    },
    localRows(newRows, oldRows) {
      this.localTilesCount = newRows * this.localCols;

      if (newRows > oldRows) {
        this.addRows();
      } else if (newRows < oldRows) {
        this.removeRows(oldRows, newRows);
      }
      this.rerenderScratchcardKey++;
      this.processChartsData();
    },
    localCols(newCols, oldCols) {
      this.localTilesCount = newCols * this.localRows;

      if (newCols) {
        if (newCols > oldCols) {
          this.addCols(newCols, oldCols);
        } else if (newCols < oldCols) {
          this.removeCols(oldCols, newCols);
        }
      }
      this.rerenderScratchcardKey++;
      this.processChartsData();
    },
    selectedTile(val) {
      if (val) {
        const index = typeof val === 'object'
          ? Number(val.value - 1)
          : Number(val - 1);

        this.tileReward = [];
        const isEmpty = this.localTiles[index].reward && Object.keys(this.localTiles[index].reward).length === 0 || !this.localTiles[index]?.reward?.rewardTypeId;

        if (this.localTiles[index]) {
          this.hasPrizes = !isEmpty;
          // this.glow = this.localTiles[index].contraints.includes('glow');
          this.isVerticallyText = this.tilesData[index].contraints.includes('isVerticallyText');
        }

        this.localAutoProbability = this.localTiles[index].autoProbability;

        if (!isEmpty) {
          this.tileReward = [this.localTiles[index].reward];
        }

        if (index !== -1) {
          this.tile.icon = this.localTiles[index].icon;
          this.tile.text = this.localTiles[index].text;
          this.tile.contraints = this.localTiles[index].contraints;
          this.rewardsData = !isEmpty ? [this.localTiles[index].reward] : [];
          this.tile.reward = this.localTiles[index].reward;
          this.tile.location = this.localTiles[index].location;
          this.tile.probability = this.localTiles[index].probability ? this.localTiles[index].probability : null;
          this.tile.background = this.localTiles[index].background ? this.localTiles[index].background : this.colorScale[index % this.colorScale.length];
          this.rerenderSelectColorKey++;
        }

        if (this.instantWinType === 1) {
          updateSelectedSection(Number(val.value), this.localTiles.length);
        }

        if (this.instantWinType === 2) {
          this.$refs.scratchcard.updateSelectedCell(Number(val.value));
        }
      }
    },
  },
  mounted() {
    this.updateSectionsOptions();
    this.getInstantWinWheelSettings();
  },
  created() {
    this.localTiles = this.tilesData.map(tile => ({ ...tile, autoProbability: false }));
    this.selectedTile = {
      label: 'Tile 1',
      value: '1'
    };
    this.processChartsData();

    if (this.entityData.constraints.includes('isVerticallyText')) {
      this.swipeTextPositionHandler(true);
    }
  },
  computed: {
    sectionsOptions() {
      return Array.from({ length: this.localTilesCount }, (_, i) => ({
        value: String(i + 1),
        label: `Tile ${ i + 1 }`
      }));
    },
    isTileSettingsAvailable() {
      return this.allowedSpacesForTileSettings.includes(this.currentSpace);
    },
    pageTitle() {
      return this.instantWinType === 1
        ? this.texts.createPage.tilesStep.spinnerTitle
        : this.texts.createPage.tilesStep.scratchCardTitle;
    }
  },
  methods: {
    ...mapActions('instantWins', ['handleUpdateInstantWins', 'handleGetInstantWins_item']),
    ...mapActions('rewards', ['handleGetRewardsByQuery', 'handleGetRewards_item']),
    ...mapActions('files', ['handleGetFileObjectsByQuery', 'handleDownloadFileObjects', 'handleUploadFileObjects']),
    ...mapActions('fileRepositories', ['handleGetFileRepositoriesByQuery']),
    async getInstantWinWheelSettings() {
      const itemId = this.$route.params.id;
      const repository = await this.handleGetFileRepositoriesByQuery({
        queryRequest: {
          must: [{
            queryField: 'name',
            queryValues: ['system-resources'],
          }]
        }
      });

      this.repositoryId = repository[0].id;


      const sortBy = [{
        queryField: 'name',
        order: 'Asc',
      }];

      const query = {
        queryRequest: {
          must: [
            {
              queryField: 'repositoryId',
              queryValues: [this.repositoryId],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: [this.folderName],
            },
            {
              queryField: 'name',
              queryValues: [itemId],
            },
          ],
          sortBy: sortBy,
          limit: this.itemsPerPage,
          skip: 0
        }
      };

      const file = await this.handleGetFileObjectsByQuery(query);

      if (file[0] && file[0].uri) {
        this.fileObject = file[0];
        const fileUri = file[0].uri;

        fetch(fileUri)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${ response.status }`);
            }
            return response.json();
          })
          .then(data => {
            if (data.wheelSettings.wheelImage) {
              this.isShowWheelImage = true;
            }

            this.localWheelSettings = data.wheelSettings;
            this.messageSettings = data.messageSettings;
          })
          .catch(error => {
            console.error('Fetch error:', error);
          });
      }
    },
    removeReward() {
      this.hasPrizes = false;
      const index = +this.selectedTile.value - 1;
      const updatedTile = { ...this.tile, autoProbability: this.localAutoProbability };
      updatedTile.reward = {};
      this.$set(this.tile, 'reward', {});
      const copy = [...this.localTiles];
      copy.splice(index, 1, updatedTile);

      this.localTiles = copy;
      this.isTilesDataSaved = false;
    },
    onProbabilityInput(newVal) {
      if (newVal === '' || isNaN(parseFloat(newVal))) {
        this.resetInvalidProbabilityField();
        this.requiredText = null;
        this.tile.probability = null;
        return;
      }

      const value = parseFloat(newVal);
      this.validateAndSaveProbability(value);
    },
    validateAndSaveProbability(value) {
      this.resetInvalidProbabilityField();

      if (value < 0) {
        this.setInvalidProbabilityField();
        this.requiredText = this.descriptions.probabilityNoNegativeNumbers;
        return;
      }

      if (value > 1) {
        this.setInvalidProbabilityField();
        this.requiredText = this.descriptions.probabilityNoNumbersGreaterThan;
        return;
      }

      // Calculate the total probability excluding the current tile's probability
      const currentIndex = +this.selectedTile.value - 1;
      const totalProbability = this.localTiles.reduce((acc, tile, index) => {
        if (index !== currentIndex) {
          return acc + (+tile.probability || 0);
        }
        return acc;
      }, 0) + value;

      const epsilon = 1e-10;

      if (totalProbability > 1 + epsilon) {
        this.requiredText = this.descriptions.probabilitySumNotExceedOne;
        this.setInvalidProbabilityField();
        return;
      }

      if (value !== 0) {
        this.tile.probability = value;
        const index = +this.selectedTile.value - 1;
        const updatedTile = { ...this.tile, probability: value, autoProbability: false };
        const copy = [...this.localTiles];
        copy.splice(index, 1, updatedTile);

        this.localTiles = copy;
      }
    },
    resetInvalidProbabilityField() {
      const invalidFieldElement = document.getElementsByName('probability')[0];
      let invalidElement = invalidFieldElement;

      if (!invalidFieldElement.classList.contains('zq--form-row')) {
        invalidElement = invalidFieldElement.parentNode;
      }
      invalidElement.classList.remove('zq-invalid');
    },
    deleteReward() {
      const tilesCopy = [...this.localTiles];
      const tile = tilesCopy[Number(this.selectedTile.value - 1)];

      tile.reward = {};
      this.tileReward = [];

      const copyConstraints = [...tile.contraints];
      const index = copyConstraints.indexOf('hasPrizes');

      if (index !== -1) {
        copyConstraints.splice(index, 1);
        tile.contraints = copyConstraints;
      }
      this.rewardsData = [];

      this.hasPrizes = false;
      this.localTiles = tilesCopy;
    },
    async updateTiles() {
      this.isReady = false;
      const instantWinData = cloneDeep(this.entityData);
      instantWinData.tiles = cloneDeep(this.localTiles);

      instantWinData.tiles.forEach(tile => {
        if (tile.reward) {
          delete tile.reward.rewardType;
          delete tile.reward.id;
          delete tile.reward.spaceName;
          delete tile.reward.created;
          delete tile.reward.entityType;
          delete tile.reward.entityId;
          delete tile.reward.translatableFields;
          delete tile.reward.translations;
        }
        delete tile.autoProbability;
      });

      delete instantWinData.spaceName;
      delete instantWinData.created;
      delete instantWinData.version;

      const body = [instantWinData];

      await this.handleUpdateInstantWins({ updateInstantWinRequestArray: body });

      setTimeout(() => {
        this.isTilesDataSaved = true;
        this.$emit('settingInitialize');
      }, 3500);
      this.isReady = true;
    },
    onInput(val) {
      if (val && val !== this.selectedTile) {
        this.selectedTile = val;
      }
    },
    createSectionStatusArray() {
      this.$emit('createSectionStatusArray');
    },
    processChartsData() {
      this.localChartsData = [];

      const tilesWithProbability = this.localTiles.filter(tile => +tile.probability !== null);
      const totalProbability = tilesWithProbability.reduce((acc, tile) => acc + +tile.probability, 0);
      const remainingProbability = 1 - totalProbability;

      const tilesWithoutProbability = this.localTiles.filter(tile => +tile.probability === null || +tile.probability === 0);
      const numTilesWithoutProbability = tilesWithoutProbability.length;

      const perTileProbability = remainingProbability / numTilesWithoutProbability;

      for (let i = 0; i < this.localTiles.length; i++) {
        if (this.localTiles[i].probability === null || +this.localTiles[i].probability === 0) {
          this.localChartsData[i] = perTileProbability;
        } else {
          this.localChartsData[i] = +this.localTiles[i].probability;
        }
      }

      this.renderChartKey++;
    },
    updateSectionsOptions() {
      this.tilesOptions = this.sectionsOptions;
    },
    handleTileSelected(val) {
      if (typeof val === 'object') {
        const selectedSection = this.sectionsOptions.find(section => section.value === String(val.cellNumber));

        if (selectedSection) {
          this.selectedTile = selectedSection;
        }
      } else {
        const selectedSection = this.sectionsOptions.find(section => section.value === String(val));

        if (selectedSection) {
          this.selectedTile = selectedSection;
        }
      }
    },
    setInvalidProbabilityField() {
      const invalidFieldElement = document.getElementsByName('probability')[0];
      let invalidElement = invalidFieldElement;

      if (!invalidFieldElement.classList.contains('zq--form-row')) {
        invalidElement = invalidFieldElement.parentNode;
      }

      invalidElement.classList.add('zq-invalid');
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          delete reward.translations;
          transformedRewards.push(reward);
        });
      }
      this.transformedRewards = transformedRewards;
      this.tile.reward = rewards[0];
      this.isShowRewardModal = false;
      this.selectedReward = {};
      this.isTilesDataSaved = false;
    },
    hasPrizesUpdate(val) {
      this.hasPrizes = val;
      this.isShowModal = true;
    },
    closeModal() {
      this.hasPrizesUpdate(false);
      this.isShowModal = false;
      this.isShowRewardModal = false;
      this.isShowRewardTemplatesModal = false;
      this.selectedReward = {};
    },
    addRewardFromTemplate() {
      this.isShowModal = false;
      this.isShowRewardTemplatesModal = true;
    },
    createReward() {
      this.isShowModal = false;
      this.isShowRewardModal = true;
    },
    selectReward(reward) {
      this.isShowRewardTemplatesModal = false;
      this.selectedReward = reward;
      this.isShowRewardModal = true;
      this.isTilesDataSaved = false;
    },
    glowUpdate(val) {
      this.glow = val;
    },
    autoProbabilityUpdate(val) {
      this.localAutoProbability = val;
      this.isTilesDataSaved = false;
    },
    validateRewardSaved(isRewardSaved) {
      this.isRewardSaved = isRewardSaved;
    },
    addCols(newCols, oldCols) {
      const tilesPerColumn = this.localRows;
      const tilesCopy = [...this.localTiles];

      for (let j = 0; j < tilesPerColumn; j++) {
        const newTile = {
          icon: '',
          text: '',
          contraints: [],
          reward: {},
          location: {
            col: Number(newCols),
            row: j + 1,
          },
          probability: null,
          autoProbability: false,
          background: '#fff'
        };

        const insertIndex = (j + 1) * this.localCols - 1;
        tilesCopy.splice(insertIndex, 0, newTile);

      }

      this.localTiles = tilesCopy;
      this.recalculateLocation();
    },
    removeCols(oldCols, newCols) {
      for (let j = 0; j < this.localRows; j++) {
        const tilesCopy = [...this.localTiles];
        const index = tilesCopy.findIndex(item => item.location.col === +oldCols && item.location.row === j + 1);

        tilesCopy.splice(index, 1);
        this.localTiles = tilesCopy;
      }

      this.recalculateLocation();
    },
    addRows() {
      for (let j = 0; j < this.localCols; j++) {
        this.localTiles.push({
          icon: '',
          text: '',
          contraints: [],
          reward: {},
          location: {
            col: j + 1,
            row: Number(this.rows),
          },
          probability: null,
          autoProbability: false,
          background: '#fff'
        });
      }

      this.recalculateLocation();
    },
    removeRows(oldRows, newRows) {
      const index = this.localTiles.length - Number(oldRows);
      this.localTiles.splice(index, +this.localCols);
      this.recalculateLocation();
    },
    recalculateLocation() {
      this.localTiles.forEach((tile, index) => {
        tile.location.col = (index % this.localCols) + 1;
        tile.location.row = Math.floor(index / this.localCols) + 1;
      });
    },
    handleDeleteFile() {
      this.tile.icon = '';
    },
    onEditorSpinButtonTextChange(html) {
      this.localWheelSettings.buttonText = html;
    },
    onEditorChange(html) {
      this.originalTileText = html;

      if (this.tile.text !== this.originalTileText) {
        this.isTilesDataSaved = false;
      } else {
        this.isTilesDataSaved = true;
      }
      this.tile.text = html;
    },
    handleWheelBackgroundColorSelected(color) {
      this.localWheelSettings.wheelBackground = color;
    },
    handleSpinButtonBackgroundColorSelected(color) {
      this.localWheelSettings.spinButtonBackground = color;
    },
    handleWheelBordersColorSelected(color) {
      this.$set(this.localWheelSettings, 'wheelBordersColor', color);
    },
    handleSpinButtonBorderColorSelected(color) {
      this.$set(this.localWheelSettings, 'spinButtonBorderColor', color);
    },
    handleDeleteWheelImageFile() {
      this.localWheelSettings.wheelImage = '';
    },
    handleDeleteWheelButtonImageFile() {
      this.localWheelSettings.wheelButtonImage = '';
    },
    handleDeleteWheelArrowImageFile() {
      this.localWheelSettings.wheelArrowImage = '';
    },
    handleDeleteWheelBorderImageFile() {
      this.localWheelSettings.wheelBorderImage = '';
    },
    showWheelImageUpdate(val) {
      this.isShowWheelImage = val;
    },
    async updateWheelSettings() {
      const template = {
        wheelSettings: this.localWheelSettings,
        messageSettings: this.messageSettings
      };

      const fileString = JSON.stringify(template);

      const parts = [
        new Blob([fileString], {
          type: 'application/json'
        })
      ];

      const file = new File(parts, this.$route.params.id, {
        lastModified: new Date(),
        type: 'application/json'
      });

      const payload = {
        filesArray: file,
        parentFolderPath: this.folderName,
        repositoryId: this.repositoryId,
        tagsArray: 'instantWins',
      };

      await this.handleUploadFileObjects(payload);
    },
    swipeTextPositionHandler(val) {
      this.isVerticallyText = val;
      this.isTilesDataSaved = false;
    },
    handleColorSelected(color) {
      const index = +this.selectedTile.value - 1;
      this.localTiles[index].background = color;
      this.tile.background = color;
      this.isTilesDataSaved = false;
    },
    handleTabToggle(index) {
      this.activeTab = this.tabsList[index].value;
    },
    handleIsScratchCardProbabilityView() {
      this.isScratchCardProbabilityView = !this.isScratchCardProbabilityView;
    },
    handleDeleteButtonIconFile() {
      this.localWheelSettings.icon = '';
    },
    handleDeleteButtonBackgroundFile() {
      this.localWheelSettings.spinButtonBackgroundImage = '';
    },
  },
};
</script>

<style scoped lang="scss">
.edit-instant-win {
  position: relative;

  .spinner {
    position: absolute;
    top: -20%;
  }
}

.create-wheel-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
}

.charts-switch-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  .tabSwitcher {
    position: relative;
    margin-left: 60px;;
  }

  .charts-switch-label {
    padding-right: 10px;
    margin: 0;
  }
}

.charts-switch-row {
  display: flex;
  align-items: center;

  .charts-switch-label {
    padding-right: 10px;
    margin: 0;
  }
}


.tile-settings {
  width: 100%;
  display: flex;
}

#section-select {
  border: none;

  &:focus-visible {
    outline: none;
  }

  .section-select-options {
    border-radius: 12px;
    border: none;
    outline: none;
  }
}

select option {
  border-radius: 12px;
  border: none;
  outline: none;
}


.probability-percentage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .probability-percentage-title {
    padding-left: 30px;
    opacity: 0.5;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .total-probability-percentage {
    padding-left: 130px;
  }
}

.content {
  position: relative;

  .message-box {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
  }
}


.location {
  display: flex;
  align-items: center;

  .location-row {
    display: flex;
    align-items: center;

    &:last-child {
      margin-left: 5px;
    }

    .input-label {
      //padding-right: 5px;
    }

    .loc {
      width: 100%;
    }
  }
}

.edit-wheel-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 80px;
}

.dividing-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #D8DBE0;
}

.reward-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
  background-color: white;
  border-radius: 12px;
}

.reward-table th,
.reward-table td {
  padding: 8px;
}

.reward-table th {
  font-size: 15px;
  font-weight: 400;
  color: #3C4B64;
  font-style: italic;
}

.reward-table td {
  font-size: 13px;
}

.reward-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

</style>
